@tailwind base;
@tailwind components;
@tailwind utilities;

.bgHero {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(32, 36, 48, 0.10) 128.82%);
    backdrop-filter: blur(100px);
}

.footerBg {
    background-image: url('../public/images/footerBg.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.globalBg {
    margin: 60px 0;
    background-image: url('../public/images/globleImage.png');
    background-size: cover;
    background-repeat: no-repeat;
}